import { Component, createSignal, ParentProps, Show } from "solid-js";
import { A } from "@solidjs/router";
import { useI18n } from "./i18n";
import { isLogged } from "./event";

export function isProduction() {
  return !window.location.href.includes("dev.gmod-integration.com");
}

const devWss = "wss://ws-dev.gmod-integration.com";
const prodWss = "wss://ws.gmod-integration.com";

export function getWSUrl(params: Array<string> = []) {
  let baseUrl = !isProduction() ? devWss : prodWss;
  baseUrl += "?action=main";
  baseUrl += "&token=" + localStorage.getItem("accessToken");
  baseUrl += "&discordID=" + localStorage.getItem("discordID");
  baseUrl += "&guildID=" + getUrlWithActualParams(":guildID");
  baseUrl += "&serverID=" + getUrlWithActualParams(":serverID");
  params.forEach((param) => {
    baseUrl += `&${param}`;
  });
  return baseUrl;
}

export const [webSocketSignal, setWebSocketSignal] = createSignal<WebSocket | null>(null);
export const [webSocketLogsMessages, setWebSocketLogsMessages] = createSignal([] as any[]);
export const [webSocketServerStatus, setWebSocketServerStatus] = createSignal({
  isWebSocketConnected: false,
  lastRequest: new Date(new Date().getTime() - 1000 * 60 * 2),
  version: "",
  versionComparator: 1,
  serverID: "",
  action: "server_status",
} as {
  isWebSocketConnected: boolean;
  lastRequest: Date;
  version: string;
  versionComparator: number;
  serverID: string;
  action: string;
});

export function initWebSocket(forceClose = false) {
  if (!isLogged()) return;

  if (webSocketSignal() && !forceClose) {
    return;
  }

  const ws = new WebSocket(getWSUrl());

  ws.onopen = () => {
    console.log("WebSocket connection opened");
  };

  ws.onmessage = (event) => {
    const eventData = JSON.parse(event.data);
    if (!eventData || !eventData.action) return;
    switch (eventData.action) {
      case "server_logs":
        if (eventData.serverID !== getUrlWithActualParams(":serverID")) return;
        setWebSocketLogsMessages((prev) => [eventData.data, ...prev]);
        break;
      case "server_status":
        if (eventData.serverID !== getUrlWithActualParams(":serverID")) return;
        eventData.lastRequest = new Date(eventData.lastRequest);
        setWebSocketServerStatus(eventData);
        break;
      default:
        break;
    }
  };

  ws.onclose = () => {
    console.log("WebSocket connection closed");
  };

  ws.onerror = (error) => {
    console.error("WebSocket error:", error);
  };

  setWebSocketSignal(ws);

  // Cleanup on component unmount
  return () => {
    ws.close();
  };
}

export function sendWebSocketMessage(event: string, data: any) {
  const ws = webSocketSignal();
  if (!ws) return;
  ws.send(JSON.stringify({ action: event, data }));
}

export const API_VERSION = "v3";
const devAPI = "https://api-dev.gmod-integration.com";
const prodAPI = "https://api.gmod-integration.com";
export const API_FQDN = (isProduction() ? prodAPI : devAPI) + "/" + API_VERSION;

const devClientID = "1136093457782415420";
const prodClientID = "1110121451501129758";
export const INVITE_URL = `https://discord.com/oauth2/authorize?client_id=${isProduction() ? prodClientID : devClientID}&permissions=1759218604441591&scope=bot`;

export function getAPIUrl(withVersion = true) {
  if (withVersion) {
    return API_FQDN;
  }
  return isProduction() ? prodAPI : devAPI;
}

export function linkifyEmails(text: string) {
  const emailPattern = /(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b)/gi;
  return text.replace(emailPattern, '<a class="text-blue hover:text-blue-active" href="mailto:$1">$1</a>');
}

export function getGuild() {
  return JSON.parse(localStorage.getItem("guilds") || "{}");
}

export function getServer() {
  return JSON.parse(localStorage.getItem("server") || "{}");
}

export function getDiscordUser() {
  return JSON.parse(localStorage.getItem("discordUser") || "{}");
}

export const NeedWebsocket: Component = () => {
  const { t } = useI18n();
  return (
    <>
      <div class="text-info flex h-12 items-center rounded-lg border-info border p-4 gap-4">
        <i class="fa-solid fa-info-circle"></i>
        <span>
          {t("tools.needWS_1", "This feature requires a") + " "}
          <A class="link" href="https://docs.gmod-integration.com/getting-started/gwsockets">
            GWSocket
          </A>{" "}
          {t("tools.needWS_2", "connection to work properly.") + " "}
        </span>
      </div>
    </>
  );
};

export interface AddonLink {
  name: string;
  link: string;
}

interface NeedAddonsProps extends ParentProps {
  addons: AddonLink[];
}

export const NeedAddon: Component<NeedAddonsProps> = (props: NeedAddonsProps) => {
  const { t } = useI18n();
  return (
    <>
      <div class="text-info flex h-12 items-center rounded-lg border-info border p-4 gap-4">
        <i class="fa-solid fa-info-circle"></i>
        <span>
          {t("tools.needAddon", "To use this feature you need to install the addon(s): ")}
          <For each={props.addons}>
            {(addon) => (
              <>
                <A class="link" href={addon.link}>
                  {addon.name}
                </A>
                {", "}
              </>
            )}
          </For>
          {t("tools.needAddon2", " to work properly.")}
        </span>
      </div>
    </>
  );
};

export function isPremium() {
  return getGuild().isPremium;
}

export const PremiumOnly: Component = () => {
  const { t } = useI18n();
  return <Show when={!isPremium()}>- {t("tools.premium_only", "Premium Only")}</Show>;
};

export const PremiumFeature: Component = (props: any) => {
  const [guildIsPremium] = createSignal(isPremium());
  const { t } = useI18n();
  return (
    <>
      <Show when={!guildIsPremium()}>
        <div class="text-amber-400 flex h-12 items-center rounded-lg border-amber-400 border p-4 gap-4">
          <i class="fas fa-crown"></i>
          <span>
            {props.message ? props.message : "This feature requires a premium plan."}{" "}
            <A class="link" href="/premium">
              {t("tools.upgrade_now", "Upgrade Now")}
            </A>
          </span>
        </div>
      </Show>
    </>
  );
};

export function getUrlWithActualParams(url: string) {
  url = url.replace(":discordID", localStorage.getItem("discordID") || "");
  url = url.replace(":serverID", JSON.parse(localStorage.getItem("server") || "{}").id || "");
  url = url.replace(":userID", JSON.parse(localStorage.getItem("discordUser") || "{}").id || "");
  url = url.replace(":guildID", JSON.parse(localStorage.getItem("guilds") || "{}").id || "");
  return url;
}

export function fetchAPI(endpoint: string, method: string, body?: any) {
  endpoint = getUrlWithActualParams(endpoint);
  return fetch(`${API_FQDN}${endpoint}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(body),
  });
}

export const PremiumBadge: Component = () => {
  const { t } = useI18n();
  return (
    <div class="badge badge-outline text-amber-400">
      <i class="fas fa-crown"></i>
      <span class="ml-2">{t("tools.premium", "Premium")}</span>
    </div>
  );
};

/*
 * Convert seconds to time
 * @param {number} seconds - The seconds to convert
 * @param {boolean} force - Force the conversion even if the time is 0 min 2digit
 * @param {Array<format>} formatDate - The format to convert the time
 */
export function convertSecToTime(seconds: number, force: boolean, formatDate: Array<"w" | "d" | "h" | "m" | "s">) {
  force = force || false;
  formatDate = formatDate || ["w", "d", "h", "m", "s"];

  // let weeks = Math.floor(seconds / 604800);
  // let days = Math.floor((seconds % 604800) / 86400);
  // let hours = Math.floor(((seconds % 604800) % 86400) / 3600);
  // let minutes = Math.floor((((seconds % 604800) % 86400) % 3600) / 60);
  // let secs = Math.floor((((seconds % 604800) % 86400) % 3600) % 60);
  const operators = {
    w: (seconds: number) => Math.floor(seconds / 604800),
    d: (seconds: number) => Math.floor((seconds % 604800) / 86400),
    h: (seconds: number) => Math.floor(((seconds % 604800) % 86400) / 3600),
    m: (seconds: number) => Math.floor((((seconds % 604800) % 86400) % 3600) / 60),
    s: (seconds: number) => Math.floor((((seconds % 604800) % 86400) % 3600) % 60),
  };

  let time = "";
  formatDate.forEach((format) => {
    let value = operators[format](seconds);
    if (value > 0 || force) {
      time += value.toString().padStart(2, "0") + format + " ";
    }
  });

  return time.trim();
}
