export enum QuerySort {
  ASC = "ASC",
  DESC = "DESC",
}

// This is what you send TO the server (resource source).
// Omit 'total' from here to avoid triggers on setQuery({ total: ... }).
export interface ClientQuery {
  limit: number;
  offset: number;
  sort: string;
  orderBy: QuerySort;
}

// The server response includes a 'query' that DOES have 'total'.
export interface ServerQuery extends ClientQuery {
  total: number;
}
